import React from 'react';
import { default as MDTextField } from '@mui/material/TextField';
import { t } from '../../../common/i18n/translate';
const createHoehenHash = (value, fieldValues, index) => {
  let hoehen = fieldValues.springen_stufen_hoehen || {};
  hoehen[index + 1] = value
  return Object.fromEntries(
    Object.entries(hoehen).filter(([key, _]) => Number(key) <= fieldValues.springen_anzahl_stufen)
  );
}
export const stufenFelder = (props) => [
  {
    key: 'springen_stufen_hoehen',
    feldTyp: 'dynamic_component',
    dynamicComponent: (fieldValues, setFieldValues) => (
      <>
        {Array.from({ length: fieldValues.springen_anzahl_stufen }).map((_, index) => (
          <MDTextField
            name="springen_stufen_hoehen"
            label={t('springen_stufen_hoehen') + ` ${index + 1}`}
            type="number"
            key={index}
            value={fieldValues.springen_stufen_hoehen ? fieldValues.springen_stufen_hoehen[index + 1] : ""}
            required={true}
            onChange={(e) => {
              e.persist();
              let value = e.target.value;
              if (value === "") {
                value = null;
              }
              setFieldValues({
                ...fieldValues,
                springen_stufen_hoehen: createHoehenHash(value, fieldValues, index)
              });
            }}
            />
        ))}
      </>
    ),
    displayCondition: values => values.springen_anzahl_stufen > 0,
  }
];

