import React from 'react';
import { t } from '../../../common/i18n/translate';
import { gewinnpunkteFelder } from './gewinnpunkte_felder';
import { stufenFelder } from './stufen_felder'

export const dressurFelder = props => [
  {
    key: 'd_start',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'autocomplete',
        name: 'kategorie_id',
        label: t('kategorie'),
        required: true,
        options: props.stammdaten.pruefungskategorien,
        optionValue: 'id',
        optionLabel: 'code',
        filterOptions: (data, options) => options.filter(o => parseInt(o.disziplin_id) === parseInt(data.disziplin_id)),
      },
      {
        feldTyp: 'autocomplete_max_selections',
        name: 'programm_ids',
        options: props.stammdaten.dresur_programme,
        optionValue: 'id',
        optionLabel: 'name',
        multiple: true,
        maxSelections: 3,
        displayCondition: values => props.stammdaten.kategorie_ids_fuer_programme.includes(parseInt(values.kategorie_id)),
      },
      {
        feldTyp: 'autocomplete',
        name: 'programm_ids',
        options: props.stammdaten.dresur_programme,
        optionValue: 'id',
        optionLabel: 'name',
        multiple: true,
        displayCondition: values => props.stammdaten.kategorie_ids_fuer_programme_no_limit.includes(parseInt(values.kategorie_id)),
      },
      {
        feldTyp: 'autocomplete',
        name: 'equipe_anzahl_nennungen',
        options: props.stammdaten.equipen,
        optionValue: 'anzahl',
        optionLabel: 'label',
        required: true,
      },
    ],
  },
  {
    key: 'd_spezifikation',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'autocomplete',
        name: 'lizenzierung_gruppe_id',
        label: t('lizenzierung_gruppe'),
        options: props.stammdaten.lizenzierung_gruppen,
        optionValue: 'id',
        optionLabel: 'bezeichnung',
        filterOptions: (data, options) => options.filter(o => parseInt(o.disziplin_id) === parseInt(data.disziplin_id)),
      },
      {
        feldTyp: 'text',
        name: 'dressur_teilnehmer',
      },
      {
        feldTyp: 'text',
        name: 'dressur_rechteck',
      },
    ],
  },
  {
    key: 'd_bemerkungen',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'text',
        name: 'bemerkungen',
        multiline: true,
      },
    ],
  },
  {
    feldTyp: 'checkbox',
    name: 'n_reiter_nur_4_und_5_jaehrige_pferde',
    additionalChanges: (value) => {
      if (value) {
        return { n_reiter_nur_4_und_5_jaehrige_pferde: true };
      }
      return {};
    },
  },
  {
    feldTyp: 'checkbox',
    name: 'r_reiter_nur_4_und_5_jaehrige_pferde',
    additionalChanges: (value) => {
      if (value) {
        return { r_reiter_nur_4_und_5_jaehrige_pferde: true };
      }
      return {};
    },
  },
];

export const fahrenFelder = props => [
  {
    key: 'f_start',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'autocomplete',
        name: 'kategorie_id',
        label: t('kategorie'),
        required: true,
        options: props.stammdaten.pruefungskategorien,
        optionValue: 'id',
        optionLabel: 'code',
        filterOptions: (data, options) => options.filter(o => parseInt(o.disziplin_id) === parseInt(data.disziplin_id)),
      },
      {
        feldTyp: 'autocomplete',
        name: 'lizenzierung_gruppe_id',
        label: t('lizenzierung_gruppe'),
        options: props.stammdaten.lizenzierung_gruppen,
        optionValue: 'id',
        optionLabel: 'bezeichnung',
        filterOptions: (data, options) => options.filter(o => parseInt(o.disziplin_id) === parseInt(data.disziplin_id)),
      },
      {
        feldTyp: 'autocomplete',
        name: 'equipe_anzahl_nennungen',
        options: props.stammdaten.equipen,
        optionValue: 'anzahl',
        optionLabel: 'label',
        required: true,
      },
      {
        feldTyp: 'checkbox',
        name: 'fahren_nur_pony',
        additionalChanges: (value) => {
          if (value) {
            return { fahren_nur_pferde: false };
          }
          return {};
        },
      },
      {
        feldTyp: 'checkbox',
        name: 'fahren_nur_pferde',
        additionalChanges: (value) => {
          if (value) {
            return { fahren_nur_pony: false };
          }
          return {};
        },
      },
    ],
  },
  {
    key: 'd_gespann',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'label',
        label: `${t('gespann')}:`,
      },
      {
        feldTyp: 'whitespace',
      },
      {
        feldTyp: 'whitespace',
      },
      {
        feldTyp: 'checkbox',
        name: 'fahren_gespanne_1_aktiv',
      },
      {
        feldTyp: 'checkbox',
        name: 'fahren_gespanne_2_aktiv',
      },
      {
        feldTyp: 'checkbox',
        name: 'fahren_gespanne_4_aktiv',
      },
      {
        feldTyp: 'checkbox',
        name: 'fahren_gespanne_tandem_aktiv',
      },
    ],
  },
  {
    key: 'f_checkboxes',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'label',
        label: `${t('geplante_teilpruefungen')}:`,
      },
      {
        feldTyp: 'checkbox',
        name: 'fahren_dressur_aktiv',
      },
      {
        feldTyp: 'checkbox',
        name: 'fahren_gelaende_aktiv',
      },
      {
        feldTyp: 'checkbox',
        name: 'fahren_hindernis_aktiv',
      },
      {
        feldTyp: 'checkbox',
        name: 'fahren_derby_aktiv',
      },
      {
        feldTyp: 'checkbox',
        name: 'fahren_multitest_aktiv',
      },
      {
        feldTyp: 'checkbox',
        name: 'fahren_freier_text_aktiv',
      },
    ],
  },
  {
    key: 'f_warnung',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'label',
        label: t('geplante_teilpruefungen_warnung'),
        style: { fontSize: '12px', fontWeight: 'bold', color: '#ff9800' },
      },
    ],
  },
  {
    key: 'f_dressur',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'text',
        name: 'fahren_dressur',
        multiline: true,
        displayCondition: values => values.fahren_dressur_aktiv,
      },
    ],
  },
  {
    key: 'f_gelaende',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'text',
        name: 'fahren_gelaende',
        multiline: true,
        displayCondition: values => values.fahren_gelaende_aktiv,
      },
    ],
  },
  {
    key: 'f_hindernis',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'text',
        name: 'fahren_hindernis',
        multiline: true,
        displayCondition: values => values.fahren_hindernis_aktiv,
      },
    ],
  },
  {
    key: 'f_derby',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'text',
        name: 'fahren_derby',
        multiline: true,
        displayCondition: values => values.fahren_derby_aktiv,
      },
    ],
  },
  {
    key: 'f_multitest',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'text',
        name: 'fahren_multitest',
        multiline: true,
        displayCondition: values => values.fahren_multitest_aktiv,
      },
    ],
  },
  {
    key: 'f_freier_text',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'text',
        name: 'fahren_freier_text',
        multiline: true,
        displayCondition: values => values.fahren_freier_text_aktiv,
      },
    ],
  },
  {
    key: 'f_bemerkungen',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'text',
        name: 'bemerkungen',
        multiline: true,
      },
    ],
  },
];

export const concoursFelder = props => [
  {
    key: 'cc_start',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'autocomplete',
        name: 'kategorie_id',
        label: t('kategorie'),
        required: true,
        options: props.stammdaten.pruefungskategorien,
        optionValue: 'id',
        optionLabel: 'code',
        filterOptions: (data, options) => options.filter(o => parseInt(o.disziplin_id) === parseInt(data.disziplin_id)),
      },
      {
        feldTyp: 'text',
        name: 'springen_anzahl_stufen',
        type: 'number',
        required: true,
        displayCondition: values => values.concours_complet_springen_aktiv,
      },
    ],
  },
  {
    key: 'cc_checkboxes',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'label',
        label: `${t('geplante_teilpruefungen')}:`,
      },
      {
        feldTyp: 'checkbox',
        name: 'concours_complet_dressur_aktiv',
      },
      {
        feldTyp: 'checkbox',
        name: 'concours_complet_springen_aktiv',
      },
      {
        feldTyp: 'checkbox',
        name: 'concours_complet_cross_aktiv',
      },
    ],
  },
  {
    key: 'cc_warnung',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'label',
        label: t('geplante_teilpruefungen_warnung'),
        style: { fontSize: '12px', fontWeight: 'bold', color: '#ff9800' },
      },
    ],
  },
  {
    key: 'cc_dressur',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'text',
        name: 'concours_complet_dressur',
        multiline: true,
        displayCondition: values => values.concours_complet_dressur_aktiv,
      },
    ],
  },
  {
    key: 'cc_springen',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'text',
        name: 'concours_complet_springen',
        multiline: true,
        displayCondition: values => values.concours_complet_springen_aktiv,
      },
    ],
  },
  {
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      ...stufenFelder(props),
    ]
  },
  {
    key: 'cc_cross',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'text',
        name: 'concours_complet_cross',
        multiline: true,
        displayCondition: values => values.concours_complet_cross_aktiv,
      },
    ],
  },
  {
    key: 'cc_bemerkungen',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'text',
        name: 'bemerkungen',
        multiline: true,
      },
    ],
  },
];

export const springenFelder = props => [
  {
    key: 'sp_start',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'autocomplete',
        name: 'kategorie_id',
        label: t('kategorie'),
        required: true,
        options: props.stammdaten.pruefungskategorien,
        optionValue: 'id',
        optionLabel: 'code',
        filterOptions: (data, options) => options.filter(o => parseInt(o.disziplin_id) === parseInt(data.disziplin_id)),
      },
      {
        feldTyp: 'autocomplete',
        name: 'wertung_id',
        label: t('wertung'),
        required: true,
        options: props.stammdaten.wertungen,
        optionValue: 'id',
        optionLabel: 'code',
        filterOptions: (data, options) => options.filter(o => parseInt(o.disziplin_id) === parseInt(data.disziplin_id)),
      },
      {
        feldTyp: 'text',
        name: 'springen_anzahl_stufen',
        type: 'number',
        required: true,
        onChange: (value) => {
          if (value > 0) {
            return { springen_anzahl_stufen: value };
          } else {
            return {};
          }
        }
      },
      {
        feldTyp: 'autocomplete',
        name: 'equipe_anzahl_nennungen',
        options: props.stammdaten.equipen,
        optionValue: 'anzahl',
        optionLabel: 'label',
        required: true,
      },
    ],
  },
  {
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      ...stufenFelder(props),
    ]
  },
  {
    key: 'sp_bemerkungen',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'text',
        name: 'bemerkungen',
        multiline: true,
      },
    ],
  },
  {
    feldTyp: 'checkbox',
    name: 'n_reiter_nur_4_und_5_jaehrige_pferde',
    additionalChanges: (value) => {
      if (value) {
        return { n_reiter_nur_4_und_5_jaehrige_pferde: true };
      }
      return {};
    },
  },
  {
    feldTyp: 'checkbox',
    name: 'r_reiter_nur_4_und_5_jaehrige_pferde',
    additionalChanges: (value) => {
      if (value) {
        return { r_reiter_nur_4_und_5_jaehrige_pferde: true };
      }
      return {};
    },
  },
  {
    feldTyp: 'checkbox',
    name: 'pruefung_hat_gwp_beschraenkungen',
    additionalChanges: (value) => {
      if (value) {
        return { pruefung_hat_gwp_beschraenkungen: true };
      }
      return {};
    },
  },
  ...gewinnpunkteFelder(props),
];

export const voltigeFelder = props => [
  {
    key: 'v_start',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'autocomplete',
        name: 'kategorie_id',
        label: t('kategorie'),
        required: true,
        options: props.stammdaten.pruefungskategorien,
        optionValue: 'id',
        optionLabel: 'code',
        filterOptions: (data, options) => options.filter(o => parseInt(o.disziplin_id) === parseInt(data.disziplin_id)),
      },
    ],
  },
  {
    key: 'v_bemerkungen',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'text',
        name: 'bemerkungen',
        multiline: true,
      },
    ],
  },
];

export const paraDressageFelder = props => [
  {
    key: 'p_start',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'autocomplete',
        name: 'kategorie_id',
        label: t('kategorie'),
        required: true,
        options: props.stammdaten.pruefungskategorien,
        optionValue: 'id',
        optionLabel: 'code',
        filterOptions: (data, options) => options.filter(o => parseInt(o.disziplin_id) === parseInt(data.disziplin_id)),
      },
    ],
  },
  {
    key: 'p_spezifikation',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'autocomplete',
        name: 'lizenzierung_gruppe_id',
        label: t('lizenzierung_gruppe'),
        options: props.stammdaten.lizenzierung_gruppen,
        optionValue: 'id',
        optionLabel: 'bezeichnung',
        filterOptions: (data, options) => options.filter(o => parseInt(o.disziplin_id) === parseInt(data.disziplin_id)),
      },
      {
        feldTyp: 'text',
        name: 'dressur_teilnehmer',
      },
      {
        feldTyp: 'text',
        name: 'dressur_rechteck',
      },
    ],
  },
  {
    key: 'p_bemerkungen',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'text',
        name: 'bemerkungen',
        multiline: true,
      },
    ],
  },
];

export const disziplinFallbackFelder = props => [
  {
    key: 'fb_start',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'autocomplete',
        name: 'kategorie_id',
        label: t('kategorie'),
        required: true,
        options: props.stammdaten.pruefungskategorien,
        optionValue: 'id',
        optionLabel: 'code',
        filterOptions: (data, options) => options.filter(o => parseInt(o.disziplin_id) === parseInt(data.disziplin_id)),
      },
    ],
  },
  {
    key: 'fb_bemerkungen',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'text',
        name: 'bemerkungen',
        multiline: true,
      },
    ],
  },
];

export const enduranceFelder = props => [
  {
    key: 'e_start',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'autocomplete',
        name: 'kategorie_id',
        label: t('kategorie'),
        required: true,
        options: props.stammdaten.pruefungskategorien,
        optionValue: 'id',
        optionLabel: 'code',
        filterOptions: (data, options) => options.filter(o => parseInt(o.disziplin_id) === parseInt(data.disziplin_id)),
      },
    ],
  },
  {
    key: 'endurance',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'text',
        name: 'endurance_distanz',
        multiline: false,
      },
      {
        feldTyp: 'text',
        name: 'endurance_teilnehmer',
        multiline: false,
      },
      {
        feldTyp: 'text',
        name: 'endurance_schlaufen',
        multiline: false,
      },
    ],
  },
  {
    key: 'e_bemerkungen',
    feldTyp: 'wrapper',
    className: 'flex-row',
    children: [
      {
        feldTyp: 'text',
        name: 'bemerkungen',
        multiline: true,
      },
    ],
  },
];
