import { PAGINATION_ITEMS_PER_PAGE } from '../constants';

export const authenticationType = document.getElementById('authentication_type').value;
export const authenticationId = document.getElementById('authentication_id').value;
export const authenticationToken = document.getElementById('authentication_token').value;
export const apiURL = document.getElementById('api_url').value;

export const authenticationHeaders = (mitJsonContentType) => {
  const commonHeaders = {
    'x-auth-type': authenticationType,
    'x-auth-id': authenticationId,
    'x-auth-token': authenticationToken,
  };
  if (mitJsonContentType) {
    return new Headers({
      ...commonHeaders,
      'content-type': 'application/json',
    });
  }
  return new Headers(commonHeaders);
};

const defaultGetOptions = {
  method: 'GET',
  headers: authenticationHeaders(false),
};

export const okCallbackFallback = (data) => {
  window.location.reload();
};

const performTextRequest = async (url, options, okCallback = okCallbackFallback, errorCallback = errorCallbackFallback) => {
  const response = await fetch(url, options);
  const data = await response.text();
  if (response.ok) {
    okCallback(data);
  } else {
    errorCallback(data);
  }
};

const errorCallbackFallback = (data, statusCode) => {
  if (statusCode === 401) {
    // window.location.href = '/benutzer/sign_in';
  } else if (statusCode === 403) {
    window.location.href = '#/rechte_beantragen';
  } else if (statusCode === 404) {
    performTextRequest('/404.html', {}, (response) => {
      const doc = document.open('text/html');
      doc.write(response);
      doc.close();
    });
  } else if (statusCode === 500) {
    performTextRequest('/500.html', {}, (response) => {
      const doc = document.open('text/html');
      doc.write(response);
      doc.close();
    });
  } else if (data.error) {
    alert(typeof data.error === 'string' ? data.error : data.error.join('\n'));
  }
};

const performJsonRequest = async (url, options, okCallback = okCallbackFallback, errorCallback = errorCallbackFallback) => {
  const response = await fetch(url, options);
  const data = await response.json();
  if (response.ok && !(data.error)) {
    okCallback(data);
  } else {
    errorCallback(data, response.status);
  }
  if (data.reload) {
    // window.location.reload();
  }
};

const performBinaryRequest = async (url, options, okCallback = okCallbackFallback, errorCallback = errorCallbackFallback) => {
  const response = await fetch(url, options);
  const data = await response.blob();
  if (response.ok) {
    okCallback(data);
  } else {
    errorCallback(data);
  }
};

export const downloadStammdaten = async (callback) => {
  const url = `${apiURL}/backend/oas/stammdaten.json?`;
  performJsonRequest(url, defaultGetOptions, callback);
};

export const downloadVeranstaltungen = async (page, filter, sortField, sortOrder, callback) => {
  const parsedFilter = {
    nur_eigene: filter.nur_eigene.toString(),
    sportjahre: filter.sportjahr.join(','),
    status: filter.status.join(','),
    sektion_rayon: filter.sektion_rayon,
    disziplinen: filter.disziplin.join(','),
    regionalverbaende: filter.regionalverband.join(','),
    rv_anlaesse: filter.rv_anlaesse.join(','),
  };
  const filterParams = Object.entries(parsedFilter).map(entry => (`${entry[0]}=${entry[1]}`)).join('&');
  const url = `${apiURL}/backend/oas/veranstaltungen.json?page=${page}&limit=${PAGINATION_ITEMS_PER_PAGE}&sortField=${sortField}&sortOrder=${sortOrder}&${filterParams}`;
  performJsonRequest(url, defaultGetOptions, callback);
};

export const downloadVeranstaltungenZumKopieren = async (aktuelleVeranstaltungId, callback) => {
  let url = `${apiURL}/backend/oas/veranstaltungen_zum_kopieren.json`;
  if (aktuelleVeranstaltungId) {
    url += `?aktuelle_veranstaltung_id=${aktuelleVeranstaltungId}`;
  }
  performJsonRequest(url, defaultGetOptions, callback);
};

export const downloadVeranstaltung = async (id, callback) => {
  const url = `${apiURL}/backend/oas/veranstaltungen/${id}.json`;
  performJsonRequest(url, defaultGetOptions, callback);
};

export const downloadKopierteVeranstaltung = async (id, callback) => {
  const url = `${apiURL}/backend/oas/veranstaltungen/${id}/kopiert.json`;
  performJsonRequest(url, defaultGetOptions, callback);
};

export const veranstaltungAusschreibungKopieren = async (fromId, toId, callback) => {
  const url = `${apiURL}/backend/oas/ausschreibung_starts/kopie.json`;
  const options = {
    method: 'POST',
    headers: authenticationHeaders(true),
    body: JSON.stringify({
      from_id: fromId,
      to_id: toId,
    }),
  };
  performJsonRequest(url, options, callback);
};

export const veranstaltungAusschreibungStarten = async (id, callback) => {
  const url = `${apiURL}/backend/oas/ausschreibung_starts.json`;
  const options = {
    method: 'POST',
    headers: authenticationHeaders(true),
    body: JSON.stringify({
      id,
    }),
  };
  performJsonRequest(url, options, callback);
};

export const downloadNennungen = async (veranstaltungId, pruefungId, callback) => {
  const url = `${apiURL}/backend/oas/veranstaltungen/${veranstaltungId}/ausschreibung_pruefung/${pruefungId}/nennungen.json`;
  performJsonRequest(url, defaultGetOptions, callback);
};

export const nennungZurueckweisen = async (veranstaltungId, pruefungId, nennungId, neuerStatus, grund, callback) => {
  const url = `${apiURL}/backend/oas/veranstaltungen/${veranstaltungId}/ausschreibung_pruefung/${pruefungId}/nennungen/${nennungId}/zurueckweisen.json`;
  const formData = {
    neuer_status: neuerStatus,
    grund,
  };

  const options = {
    method: 'POST',
    headers: authenticationHeaders(true),
    body: JSON.stringify(formData),
  };

  performJsonRequest(url, options);
};

export const updateVeranstaltung = async (formData, veranstaltungId) => {
  const forId = veranstaltungId ?? formData.id;

  let method = 'POST';
  let url = `${apiURL}/backend/oas/veranstaltungen/`;
  if (forId) {
    url += `${forId}.json`;
    method = 'PUT';
  }
  const options = {
    method,
    headers: authenticationHeaders(true),
    body: JSON.stringify(formData),
  };

  performJsonRequest(url, options, (data) => {
    if (data.new_id) {
      window.location.hash = `#/${data.new_id}/edit`;
    } else if (data.reload) {
      window.location.reload();
    }
  });
};

export const updateVeranstaltungStatus = async (id, status, requestBody = {}) => {
  const url = `${apiURL}/backend/oas/veranstaltungen/${id}/update_status`;
  const options = {
    method: 'POST',
    headers: authenticationHeaders(true),
    body: JSON.stringify({
      ...requestBody,
      status,
    }),
  };
  performJsonRequest(url, options);
};

export const loescheVeranstaltung = async (id) => {
  const url = `${apiURL}/backend/oas/veranstaltungen/${id}`;
  const options = {
    method: 'DELETE',
    headers: authenticationHeaders(true),
  };
  performJsonRequest(url, options, () => { window.location.hash = '#/'; });
};

export const updateBeteiligung = async (veranstaltungId, formData) => {
  let method = 'POST';
  let url = `${apiURL}/backend/oas/veranstaltungen/${veranstaltungId}/veranstaltungsbeteiligungen/`;
  if (formData.id) {
    method = 'PUT';
    url += `${formData.id}.json`;
  }

  const options = {
    method,
    headers: authenticationHeaders(true),
    body: JSON.stringify(formData),
  };

  performJsonRequest(url, options);
};

export const deleteBeteiligung = async (veranstaltungId, id) => {
  const url = `${apiURL}/backend/oas/veranstaltungen/${veranstaltungId}/veranstaltungsbeteiligungen/${id}.json`;
  const options = {
    method: 'DELETE',
    headers: authenticationHeaders(true),
  };

  performJsonRequest(url, options);
};

export const updateFusstext = async (veranstaltungId, formData) => {
  let method = 'POST';
  let url = `${apiURL}/backend/oas/veranstaltungen/${veranstaltungId}/fusstexte/`;
  if (formData.id) {
    method = 'PUT';
    url += `${formData.id}.json`;
  }

  const options = {
    method,
    headers: authenticationHeaders(true),
    body: JSON.stringify(formData),
  };

  performJsonRequest(url, options);
};

export const deleteFusstext = async (veranstaltungId, id) => {
  const url = `${apiURL}/backend/oas/veranstaltungen/${veranstaltungId}/fusstexte/${id}.json`;
  const options = {
    method: 'DELETE',
    headers: authenticationHeaders(true),
  };

  performJsonRequest(url, options);
};

export const updateStallungen = async (veranstaltungId, formData) => {
  const url = `${apiURL}/backend/oas/veranstaltungen/${veranstaltungId}/stallungen.json`;

  const options = {
    method: 'PATCH',
    headers: authenticationHeaders(true),
    body: JSON.stringify(formData),
  };

  performJsonRequest(url, options);
};

export const deleteStallungen = async (veranstaltungId) => {
  const url = `${apiURL}/backend/oas/veranstaltungen/${veranstaltungId}/stallungen.json`;

  const options = {
    method: 'DELETE',
    headers: authenticationHeaders(true),
  };

  performJsonRequest(url, options);
};

export const updatePruefung = async (veranstaltungId, formData, callback) => {
  let method = 'POST';
  let url = `${apiURL}/backend/oas/veranstaltungen/${veranstaltungId}/pruefungen/`;
  if (formData.id) {
    method = 'PUT';
    url += `${formData.id}.json`;
  }

  const options = {
    method,
    headers: authenticationHeaders(true),
    body: JSON.stringify(formData),
  };

  performJsonRequest(url, options, callback);
};

export const deletePruefung = async (veranstaltungId, id, callback) => {
  const url = `${apiURL}/backend/oas/veranstaltungen/${veranstaltungId}/pruefungen/${id}.json`;
  const options = {
    method: 'DELETE',
    headers: authenticationHeaders(true),
  };

  performJsonRequest(url, options, callback);
};

export const updateKontodaten = async (veranstaltungId, formData) => {
  let method = 'POST';
  let url = `${apiURL}/backend/oas/veranstaltungen/${veranstaltungId}/zahlungsverbindungen/`;
  if (formData.id) {
    method = 'PUT';
    url += `${formData.id}.json`;
  }

  const options = {
    method,
    headers: authenticationHeaders(true),
    body: JSON.stringify(formData),
  };

  performJsonRequest(url, options);
};

export const updateGeoLocation = async (veranstaltungId, formData) => {
  let method = 'PUT';
  let url = `${apiURL}/backend/oas/veranstaltungen/${veranstaltungId}`;

  if (formData.id) {
    url += `${formData.id}.json`;
  }

  const options = {
    method,
    headers: authenticationHeaders(true),
    body: JSON.stringify(formData),
  };

  performJsonRequest(url, options);
}

export const listAusschreibungBilder = async (veranstaltungId, callback) => {
  const url = `${apiURL}/backend/oas/veranstaltungen/${veranstaltungId}/ausschreibung_bilder.json`;
  const options = {
    method: 'GET',
    headers: authenticationHeaders(true),
  };
  performJsonRequest(url, options, callback);
};

export const listAusschreibungBilderMitListe = async (veranstaltungId, maxBreite, maxHoehe, callback) => {
  const url = `${apiURL}/backend/oas/veranstaltungen/${veranstaltungId}/ausschreibung_bilder.json?bildreihe_max_breite=${maxBreite}&bildreihe_max_hoehe=${maxHoehe}`;
  const options = {
    method: 'GET',
    headers: authenticationHeaders(true),
  };
  performJsonRequest(url, options, callback);
};

export const loadAusschreibungBild = async (veranstaltungId, position, style, callback) => {
  // dont allow erroneous style
  const forStyle = ['original', 'printable'].includes(style) ? style : 'original';
  const url = `${apiURL}/backend/oas/veranstaltungen/${veranstaltungId}/ausschreibung_bilder/${position}.json?style=${style}`;
  const requestCallback = (blob) => {
    if (blob.type === 'application/json') {
      callback(undefined);
    } else {
      callback(URL.createObjectURL(blob));
    }
  };
  performBinaryRequest(url, defaultGetOptions, requestCallback, requestCallback);
};

export const uploadAusschreibungBild = async (veranstaltungId, rawData, callback) => {
  const formData = new FormData();
  Object.keys(rawData).forEach((name) => {
    formData.append(name, rawData[name]);
  });

  const url = `${apiURL}/backend/oas/veranstaltungen/${veranstaltungId}/ausschreibung_bilder.json`;
  // Content-Type müssen wir bei Multipart überspringen - wird von fetch selbst gesetzt, inklusive automatisch generierter Separator
  const options = {
    method: 'POST',
    headers: authenticationHeaders(false),
    body: formData,
  };
  performJsonRequest(url, options, (data) => {
    callback(data.url);
  });
};

export const deleteAusschreibungBild = async (veranstaltungId, position, callback) => {
  const url = `${apiURL}/backend/oas/veranstaltungen/${veranstaltungId}/ausschreibung_bilder/${position}.json`;
  const options = {
    method: 'DELETE',
    headers: authenticationHeaders(true),
  };

  performJsonRequest(url, options, callback);
};

export const updateVeranstalterStartplaetze = async (veranstaltungId, pruefungId, anzahlStartplaetze) => {
  const url = `${apiURL}/backend/oas/veranstaltungen/${veranstaltungId}/pruefungen/${pruefungId}/veranstalter_startplaetze.json`;
  const formData = {
    anzahl_veranstalter_startplaetze: anzahlStartplaetze,
  };

  const options = {
    method: 'PATCH',
    headers: authenticationHeaders(true),
    body: JSON.stringify(formData),
  };

  performJsonRequest(url, options);
};

export const nachnennphaseStarten = async (veranstaltungId, pruefungId, formData) => {
  const url = `${apiURL}/backend/oas/veranstaltungen/${veranstaltungId}/pruefungen/${pruefungId}/nachnennphase.json`;

  const options = {
    method: 'POST',
    headers: authenticationHeaders(true),
    body: JSON.stringify(formData),
  };

  performJsonRequest(url, options);
};

export const nachnennphaseStoppen = async (veranstaltungId, pruefungId) => {
  const url = `${apiURL}/backend/oas/veranstaltungen/${veranstaltungId}/pruefungen/${pruefungId}/nachnennphase.json`;

  const options = {
    method: 'DELETE',
    headers: authenticationHeaders(true),
  };

  performJsonRequest(url, options);
};

export const nachnennphaseKommentarAktualisieren = async (veranstaltungId, pruefungId, kommentar) => {
  const url = `${apiURL}/backend/oas/veranstaltungen/${veranstaltungId}/pruefungen/${pruefungId}/nachnennphase.json`;
  const formData = {
    hinweis_nachnennphase: kommentar,
  };

  const options = {
    method: 'PATCH',
    headers: authenticationHeaders(true),
    body: JSON.stringify(formData),
  };

  performJsonRequest(url, options);
};

export const downloadDokumente = async (veranstaltungId, callback) => {
  const url = `${apiURL}/backend/oas/veranstaltungen/${veranstaltungId}/dokumente.json`;
  const options = {
    method: 'GET',
    headers: authenticationHeaders(true),
  };
  performJsonRequest(url, options, callback);
};

export const starterkarteDrucken = async (url) => {
  const options = {
    method: 'POST',
    headers: authenticationHeaders(true),
  };
  performJsonRequest(`${apiURL}${url}`, options);
};

export const veranstalterRechteAnfordern = async (regionalverbandId, veranstalterId, kommentar, callback) => {
  const url = `${apiURL}/backend/oas/veranstalter_person_berechtigungen`;
  const formData = {
    regionalverband_id: regionalverbandId,
    veranstalter_id: veranstalterId,
    kommentar,
  };

  const options = {
    method: 'POST',
    headers: authenticationHeaders(true),
    body: JSON.stringify(formData),
  };
  performJsonRequest(url, options, callback);
};
