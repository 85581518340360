import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Paper } from '@mui/material';
import { formatDate } from '../../../common/modules/helpers';
import { t } from '../../../common/i18n/translate';

import { listAusschreibungBilderMitListe, loadAusschreibungBild } from '../../../common/modules/api';

import './index.scss';
import { pruefungenTabelle } from './pruefungsDetails';
import { MUI_PAPER_ELEVATION_LEVELS } from '../../../common/constants';

import LanguageIcon from '@mui/icons-material/Language';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { grey } from "@mui/material/colors";

function AusschreibungVorschau(props) {
  const veranstaltung = props.veranstaltungen.current;
  const { kopfdaten, beteiligungen, pruefungen, fussdaten } = props.veranstaltungen.current.forms;

  const [bildreihe, setBildreihe] = useState(null);

  const [bildBildreihe1, setBildBildreihe1] = useState();
  const [bildBildreihe2, setBildBildreihe2] = useState();
  const [bildBildreihe3, setBildBildreihe3] = useState();
  const [bildBildreihe4, setBildBildreihe4] = useState();
  const [bildBildreihe5, setBildBildreihe5] = useState();
  const [bildBildreihe6, setBildBildreihe6] = useState();
  const [bildBildreihe7, setBildBildreihe7] = useState();
  const [bildGanzeBreite, setBildGanzeBreite] = useState();
  const [bildLinks, setBildLinks] = useState();
  const [bildRechts, setBildRechts] = useState();

  const veranstaltungsLocale = (veranstaltung || {}).locale;

  let hatWertungen = false;
  if (pruefungen.some(p => p.wertung_text)) hatWertungen = true;

  useEffect(() => {
    listAusschreibungBilderMitListe(veranstaltung.id, 98.0, 7.0, (response) => {
      setBildreihe(response.bildreihe);
    });
    loadAusschreibungBild(veranstaltung.id, 'bildreihe_7_1', 'original', (url) => {
      setBildBildreihe1(url);
    });
    loadAusschreibungBild(veranstaltung.id, 'bildreihe_7_2', 'original', (url) => {
      setBildBildreihe2(url);
    });
    loadAusschreibungBild(veranstaltung.id, 'bildreihe_7_3', 'original', (url) => {
      setBildBildreihe3(url);
    });
    loadAusschreibungBild(veranstaltung.id, 'bildreihe_7_4', 'original', (url) => {
      setBildBildreihe4(url);
    });
    loadAusschreibungBild(veranstaltung.id, 'bildreihe_7_5', 'original', (url) => {
      setBildBildreihe5(url);
    });
    loadAusschreibungBild(veranstaltung.id, 'bildreihe_7_6', 'original', (url) => {
      setBildBildreihe6(url);
    });
    loadAusschreibungBild(veranstaltung.id, 'bildreihe_7_7', 'original', (url) => {
      setBildBildreihe7(url);
    });
    loadAusschreibungBild(veranstaltung.id, 'ganze_breite', 'original', (url) => {
      setBildGanzeBreite(url);
    });
    loadAusschreibungBild(veranstaltung.id, 'links', 'original', (url) => {
      setBildLinks(url);
    });
    loadAusschreibungBild(veranstaltung.id, 'rechts', 'original', (url) => {
      setBildRechts(url);
    });
  }, []);

  let anzuzeigendeBeteiligungen = beteiligungen.slice();
  anzuzeigendeBeteiligungen = anzuzeigendeBeteiligungen.filter(beteiligung => !beteiligung.ist_oas_funktion);
  if (veranstaltung.forms.veranstaltungsdaten.veranstalter_publizieren) {
    anzuzeigendeBeteiligungen.unshift({
      funktion_bezeichnung: t('veranstalter', veranstaltungsLocale),
      funktion_string: veranstaltung.forms.veranstaltungsdaten.veranstalter_name,
    });
  }
  const beteiligungenLinks = [];
  const beteiligungenRechts = [];

  while (anzuzeigendeBeteiligungen.length > 0) {
    if (beteiligungenLinks.length <= beteiligungenRechts.length) {
      const beteiligung = anzuzeigendeBeteiligungen.shift();
      beteiligungenLinks.push(beteiligung);
      while (
        anzuzeigendeBeteiligungen.length > 0
        && anzuzeigendeBeteiligungen[0].funktion_bezeichnung === beteiligung.funktion_bezeichnung
      ) {
        beteiligungenLinks.push(anzuzeigendeBeteiligungen.shift());
      }
    } else {
      const beteiligung = anzuzeigendeBeteiligungen.pop();
      beteiligungenRechts.unshift(beteiligung);
      while (
        anzuzeigendeBeteiligungen.length > 0
        && anzuzeigendeBeteiligungen[anzuzeigendeBeteiligungen.length - 1].funktion_bezeichnung === beteiligung.funktion_bezeichnung
      ) {
        beteiligungenRechts.unshift(anzuzeigendeBeteiligungen.pop());
      }
    }
  }

  let letzteBezeichnungLinks;
  let letzteBezeichnungRechts;

  return (
    <div className="ausschreibung-vorschau">
      <Paper className="padding-1" elevation={MUI_PAPER_ELEVATION_LEVELS.SECOND}>
        <table width="100%">
          <tbody>
            {
            (bildreihe !== null && bildreihe.length > 0) && (
            <tr>
              <td colSpan={3}>
                <div style={{ textAlign: 'center' }}>
                  <span style={{
                    padding: 0,
                    margin: 0,
                    width: (`${bildreihe[0].breite }%`),
                    height: (`${bildreihe[0].hoehe }%`),
                    display: 'inline-block',
                    verticalAlign: 'top',
                  }}
                  >
                    {bildBildreihe1 && <img src={bildBildreihe1} alt="" width="100%" height="100%" />}
                  </span>
                  {bildreihe[0].abstand_nachher > 0
                  && <span style={{ padding: 0, margin: 0, display: 'inline-block', width: `${bildreihe[0].abstand_nachher }%` }} />}
                  <span style={{
                    padding: 0,
                    margin: 0,
                    width: (`${bildreihe[1].breite }%`),
                    height: (`${bildreihe[1].hoehe }%`),
                    display: 'inline-block',
                    verticalAlign: 'top',
                  }}
                  >
                    {bildBildreihe2 && <img src={bildBildreihe2} alt="" width="100%" height="100%" />}
                  </span>
                  {bildreihe[1].abstand_nachher > 0
                  && <span style={{ padding: 0, margin: 0, display: 'inline-block', width: `${bildreihe[1].abstand_nachher }%` }} />}
                  <span style={{
                    padding: 0,
                    margin: 0,
                    width: (`${bildreihe[2].breite }%`),
                    height: (`${bildreihe[2].hoehe }%`),
                    display: 'inline-block',
                    verticalAlign: 'top',
                  }}
                  >
                    {bildBildreihe3 && <img src={bildBildreihe3} alt="" width="100%" height="100%" />}
                  </span>
                  {bildreihe[2].abstand_nachher > 0
                  && <span style={{ padding: 0, margin: 0, display: 'inline-block', width: `${bildreihe[2].abstand_nachher }%` }} />}
                  <span style={{
                    padding: 0,
                    margin: 0,
                    width: (`${bildreihe[3].breite }%`),
                    height: (`${bildreihe[3].hoehe }%`),
                    display: 'inline-block',
                    verticalAlign: 'top',
                  }}
                  >
                    {bildBildreihe4 && <img src={bildBildreihe4} alt="" width="100%" height="100%" />}
                  </span>
                  {bildreihe[3].abstand_nachher > 0
                  && <span style={{ padding: 0, margin: 0, display: 'inline-block', width: `${bildreihe[3].abstand_nachher }%` }} />}
                  <span style={{
                    padding: 0,
                    margin: 0,
                    width: (`${bildreihe[4].breite }%`),
                    height: (`${bildreihe[4].hoehe }%`),
                    display: 'inline-block',
                    verticalAlign: 'top',
                  }}
                  >
                    {bildBildreihe5 && <img src={bildBildreihe5} alt="" width="100%" height="100%" />}
                  </span>
                  {bildreihe[4].abstand_nachher > 0
                  && <span style={{ padding: 0, margin: 0, display: 'inline-block', width: `${bildreihe[4].abstand_nachher }%` }} />}
                  <span style={{
                    padding: 0,
                    margin: 0,
                    width: (`${bildreihe[5].breite }%`),
                    height: (`${bildreihe[5].hoehe }%`),
                    display: 'inline-block',
                    verticalAlign: 'top',
                  }}
                  >
                    {bildBildreihe6 && <img src={bildBildreihe6} alt="" width="100%" height="100%" />}
                  </span>
                  {bildreihe[5].abstand_nachher > 0
                  && <span style={{ padding: 0, margin: 0, display: 'inline-block', width: `${bildreihe[5].abstand_nachher }%` }} />}
                  <span style={{
                    padding: 0,
                    margin: 0,
                    width: (`${bildreihe[6].breite }%`),
                    height: (`${bildreihe[6].hoehe }%`),
                    display: 'inline-block',
                    verticalAlign: 'top',
                  }}
                  >
                    {bildBildreihe7 && <img src={bildBildreihe7} alt="" width="100%" height="100%" />}
                  </span>
                  {bildreihe[6].abstand_nachher > 0
                  && <span style={{ padding: 0, margin: 0, display: 'inline-block', width: `${bildreihe[6].abstand_nachher }%` }} />}
                </div>
              </td>
            </tr>
            )
          }
            {
            bildGanzeBreite && (
            <tr>
              <td colSpan={3}>
                <div style={{ textAlign: 'center' }}>
                  <img src={bildGanzeBreite} alt="" />
                </div>
              </td>
            </tr>
            )
          }
            <tr>
              <td>
                <div style={{ textAlign: 'left' }}>
                  {bildLinks && <img src={bildLinks} alt="" style={{ maxWidth: '200px', maxHeight: '120px' }} />}
                </div>
              </td>
              <td>
                <div style={{ textAlign: 'center' }}>
                  <h1>{kopfdaten.erste_titelzeile}</h1>
                  <h2>{kopfdaten.zweite_titelzeile}</h2>
                  <h3>{kopfdaten.dritte_titelzeile}</h3>
                </div>
              </td>
              <td>
                <div style={{ textAlign: 'right' }}>
                  {bildRechts && <img src={bildRechts} alt="" style={{ maxWidth: '200px', maxHeight: '120px' }} />}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="mt-1 flex-row">
          <table className="beteiligungen" style={{ width: '55.45%' }}>
            <tbody>
              {beteiligungenLinks.map((beteiligung) => {
                const eintrag = (
                  <tr key={beteiligung.id}>
                    <th>
                      {beteiligung.funktion_bezeichnung !== letzteBezeichnungLinks ? beteiligung.funktion_bezeichnung : ''}
                    </th>
                    <td>
                      {
                    beteiligung.funktion_string
                      ? beteiligung.funktion_string
                      : `${beteiligung.person_name}, ${beteiligung.person_ort} ${beteiligung.person_kanton}`
                  }
                      {
                    beteiligung.nummer && ` (${beteiligung.nummer})`
                  }
                    </td>
                  </tr>
                );
                letzteBezeichnungLinks = beteiligung.funktion_bezeichnung;
                return eintrag;
              })}
            </tbody>
          </table>
          <table className="beteiligungen" style={{ width: '44.55%' }}>
            <tbody>
              {beteiligungenRechts.map((beteiligung) => {
                const eintrag = (
                  <tr key={beteiligung.id}>
                    <th style={{ paddingLeft: '1em' }}>
                      {beteiligung.funktion_bezeichnung !== letzteBezeichnungRechts ? beteiligung.funktion_bezeichnung : ''}
                    </th>
                    <td>
                      {
                    beteiligung.funktion_string
                      ? beteiligung.funktion_string
                      : `${beteiligung.person_name}, ${beteiligung.person_ort} ${beteiligung.person_kanton}`
                  }
                      {
                    beteiligung.nummer && ` (${beteiligung.nummer})`
                  }
                    </td>
                  </tr>
                );
                letzteBezeichnungRechts = beteiligung.funktion_bezeichnung;
                return eintrag;
              })}
            </tbody>
          </table>
        </div>

        <div className="mt-0-5 flex-row size-bigger">
          {
            !!veranstaltung.nennschluss_definitiv && (
            <div className="flex-1">
              {t('nennschluss', veranstaltungsLocale)}
              :
              <strong>{formatDate(new Date(veranstaltung.nennschluss_definitiv))}</strong>
            </div>
            )
          }
          {
            !!veranstaltung.nachnennphase_start && (
            <div className="flex-1">
              {t('nachnennphase_start', veranstaltungsLocale)}
              :
              <strong>
                {formatDate(new Date(veranstaltung.nachnennphase_start))}
                {' '}
                21:00
              </strong>
            </div>
            )
          }
          {
            !!veranstaltung.nennphase_start && (
            <div className="flex-1">
              {t('nennphase_start', veranstaltungsLocale)}
              {' '}
              <strong>{formatDate(new Date(veranstaltung.nennphase_start))}</strong>
              {' '}
              {t('nennphase_ab_21_uhr', veranstaltungsLocale)}
            </div>
            )
          }
          {
            veranstaltung.website && (
              <div className="flex-1 text-right website" style={{ margin: "4px" }}>
                {veranstaltung.website}
              </div>
            )
          }
          {
            (veranstaltung.lat && veranstaltung.lng) && (
              <div className="text-right website">
                <a
                  href={`https://www.google.com/maps?q=${veranstaltung.lat},${veranstaltung.lng}`}
                  target={"_blank"}>
                  <LocationOnIcon sx={{ color: grey[900] }} />
                </a>
              </div>
            )
          }
        </div>

        {
          veranstaltung.infotext && (
          <div className="flex-1 infotext">
            {veranstaltung.infotext}
          </div>
          )
        }

        <div className="mt-0-5">
          {pruefungenTabelle(pruefungen, veranstaltungsLocale)}
        </div>

        <table className="fusszeile mt-1">
          <tbody>
            {fussdaten.map(fusstext => (
              <tr key={fusstext.index}>
                <th>{fusstext.titel}</th>
                <td>{fusstext.inhalt}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div style={{ textAlign: 'right', width: '100%', marginTop: '10px' }}>
          {veranstaltung.sequenz_nummer}
        </div>
      </Paper>
    </div>
  );
}

const mapStateToProps = state => ({
  veranstaltungen: state.veranstaltungen,
  stammdaten: state.stammdaten,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AusschreibungVorschau);
