import React, { useState } from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { t } from './i18n/translate';

const GOOGLE_MAPS_API_KEY = 'AIzaSyCuiK1kdwjaAM1kXJXCzBRtgPVQAUt0rjE';

function GoogleMapField({ default_lat, default_lng, lat, lng, onLocationChange }) {
  const { isLoaded } = useLoadScript({ googleMapsApiKey: GOOGLE_MAPS_API_KEY });
  const [centerLocation, setCenterLocation] = useState({
    lat: lat === null ? parseFloat(default_lat) : lat,
    lng: lng === null ? parseFloat(default_lng) : lng,
  });

  const [location, setLocation] = useState({
    lat: lat,
    lng: lng,
  });

  const mapContainerStyle = {
    width: '100%',
    height: '600px',
  };

  const handleMapClick = (event) => {
    const newLocation = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setLocation(newLocation);
    setCenterLocation(newLocation);
    onLocationChange(newLocation);
  };

  let url = `https://www.google.com/maps?q=${location.lat},${location.lng}`
  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={centerLocation}
          zoom={15}
          onClick={handleMapClick}
          options={{
            streetViewControl: false,
            mapTypeId: "satellite",
          }}
        >
          {location?.lat !== null && location?.lng !== null && <Marker position={location} />}
        </GoogleMap>
      ) : (
        <p>Loading Map...</p>
      )}
      <br/>
      <label>{t('maps_url')}</label>
      <br/>
      <a href={url} target="_blank" rel="noreferrer">{url}</a>

    </>
  );
}

export default GoogleMapField;
