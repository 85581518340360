import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { t, getLanguage } from '../../../common/i18n/translate';
import Formular from '../../../common/Formular';
import {updateGeoLocation} from '../../../common/modules/api';
import {benutzerWurdeImpersoniert} from "../../../common/modules/helpers";

function GeoLocation(props) {
  const [formData, setFormData] = useState(props.formDataGeoLocation || { default_lat: 0, default_lng: 0, lat: null, lng: null });
  const handleLocationChange = (newLocation) => {
    setFormData((prevData) => ({ ...prevData, ...newLocation }));
  };

  const formFieldsGeoLocation = [
    { feldTyp: 'hidden', name: 'id' },
    {
      key: 1,
      feldTyp: 'fieldset',
      label: t('geo_location'),
      children: [
        {
          key: '1.1',
          feldTyp: 'fieldset',
          label: t('geo_location_info'),
        },
        {
          key: '1.2',
          feldTyp: 'google_map',
          name: 'google_map',
          default_lat: formData.default_lat,
          default_lng: formData.default_lng,
          lat: formData.lat === null ? null : parseFloat(formData.lat),
          lng: formData.lng === null ? null : parseFloat(formData.lng),
          onLocationChange: handleLocationChange,
        }
      ],
    },
  ];
  return (
    <>
      <Formular
        formId="geo_location"
        disabled={props.veranstaltung.id &&
          !benutzerWurdeImpersoniert() && !(
          props.veranstaltung.status === 'in_erfassung'
          || props.veranstaltung.status === 'zur_voranzeige'
          || props.veranstaltung.status === 'in_ausschreibung'
          || props.veranstaltung.status === 'zur_kontrolle_svps'
          || props.veranstaltung.status === 'zur_kontrolle_extern'
          || props.veranstaltung.status === 'geplant'
        )}
        fields={formFieldsGeoLocation}
        defaultValues={formData}
        handleSubmit={(newFormData) => updateGeoLocation(props.veranstaltung.id, newFormData)}
        wrapInPaper
      />
    </>
  );
}

const mapStateToProps = state => ({
  veranstaltung: state.veranstaltungen.current,
  formDataGeoLocation: state.veranstaltungen.current.forms.geo_location,
  stammdaten: state.stammdaten[getLanguage()],
});

export default connect(mapStateToProps)(GeoLocation);
