import type { Middleware } from 'redux';

/* eslint-disable no-console */
const ReduxLogger: Middleware = store => next => (action) => {
  if (typeof action === 'function') {
    return next(action);
  }
  // console.group(action.type);
  // console.info('dispatching', action);
  const result = next(action);
  // console.log('next state', store.getState());
  // console.groupEnd();
  return result;
};

export default ReduxLogger;
