import React, { useState, useEffect, useMemo } from 'react';
import { Tab, Tabs, useMediaQuery, useTheme, Icon } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { benutzerWurdeImpersoniert } from '../../../common/modules/helpers';

import { t } from '../../../common/i18n/translate';
import './index.scss';

const getDetailNavItems = (veranstaltung) => {
  const items = [{ key: 'veranstaltungsdaten', title: t('veranstaltungsdaten') }];

  if (!veranstaltung) {
    return items;
  }

  const istGeplant = veranstaltung.status === 'geplant';
  const istInInseratCheck = veranstaltung.status === 'zur_kontrolle_svps' || veranstaltung.status === 'zur_kontrolle_extern';
  const istInAusschreibung = veranstaltung.status === 'in_ausschreibung';
  const istDurchgefuehrt = veranstaltung.status === 'durchgefuehrt';
  const istAbgesagt = veranstaltung.status === 'abgesagt';
  const istGeplantOderDurch = istGeplant || istDurchgefuehrt || istAbgesagt;
  const versteckeAusschreibungChildren = istGeplantOderDurch && !benutzerWurdeImpersoniert();
  const istGeplantOderDurchUndImpersoniert = istGeplantOderDurch && benutzerWurdeImpersoniert();

  if (veranstaltung.hat_inserat) {
    if (istInInseratCheck) {
      items.push({ key: 'ausschreibung', title: t('ausschreibung') });
    } else {
      if (!istGeplant) {
        items.push(
          { key: 'konto_infos', title: t('konto_infos') }
        );
      }

      const ausschreibungChildren = versteckeAusschreibungChildren ? [] : [
        { key: 'kopfdaten', title: t('kopfdaten'), icon: 'publish' },
        { key: 'beteiligungen', title: t('beteiligungen'), icon: 'people' },
        { key: 'pruefungen', title: t('pruefungen'), icon: 'format_list_numbered' },
        { key: 'fussdaten', title: t('fussdaten'), icon: 'download' },
      ];
      items.push({ key: 'ausschreibung', title: t('ausschreibung'), children: ausschreibungChildren });
    }

    if (istInAusschreibung || istInInseratCheck || istGeplant || istGeplantOderDurchUndImpersoniert) {
      items.push({ key: 'geo_location', title: t('geo_location') });
    }

    if (istGeplantOderDurchUndImpersoniert || (!istGeplant && (istInAusschreibung || veranstaltung.forms.stallungen.stallungsangebot_aktiv))) {
      items.push({ key: 'stallungen', title: t('stallungen') });
    }

    if (istGeplant || istDurchgefuehrt || veranstaltung.hat_nennungen) {
      items.push({ key: 'nennungen', title: t('nennungen') });
    }

    if (veranstaltung.hat_dokumente) {
      items.push({ key: 'dokumente', title: t('dokumente') });
    }
  } else if (istInAusschreibung) {
    items.push({ key: 'ausschreibung_starten', title: t('ausschreibung_starten') });
  }

  return items;
};

function DetailNav({ veranstaltung }) {
  const [primaryNavValue, setPrimaryNavValue] = useState();
  const [secondaryNavValue, setSecondaryNavValue] = useState();
  const location = useLocation();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const currentPrimaryHasChildren = useMemo(() => getDetailNavItems(veranstaltung).find(item => item.key === primaryNavValue)?.children?.length > 0, [veranstaltung, primaryNavValue]);

  const navItems = getDetailNavItems(veranstaltung);

  const setBothNavForKey = (anyNavKey) => {
    const mainItemForChildRoute = getDetailNavItems(veranstaltung).find(item => item?.children?.some(cItem => cItem.key === anyNavKey));
    const matchingPrimaryRoute = getDetailNavItems(veranstaltung).find(item => item.key === anyNavKey);
    if (mainItemForChildRoute) {
      setPrimaryNavValue(mainItemForChildRoute.key);
      setSecondaryNavValue(anyNavKey);
    } else if (matchingPrimaryRoute) {
      setPrimaryNavValue(anyNavKey);
      setSecondaryNavValue(null); // reset, better than automatically set first
      // if (matchingPrimaryRoute?.children?.length > 0) {
      //   setSecondaryNavValue(matchingPrimaryRoute.children[0].key);
      // }
    }
  };

  // sync nav state with URL location
  useEffect(() => {
    const keyFromURL = location.pathname.split('/').slice(-1)[0];
    const activeKey = getDetailNavItems(veranstaltung).some(item => item.key === keyFromURL || item?.children?.some(cItem => cItem.key === keyFromURL)) ? keyFromURL : 'veranstaltungsdaten';
    setBothNavForKey(activeKey);
  }, [location]);

  // if (primaryNavValue == null || (currentPrimaryHasChildren && secondaryNavValue == null)) {
  //   return null;
  // }

  if (primaryNavValue == null) {
    return null;
  }

  return (
    <>
      <Tabs
        id="primary-nav"
        value={primaryNavValue}
        onChange={(e, newPrimaryValue) => setBothNavForKey(newPrimaryValue)}
        // indicatorColor="secondary"
        // textColor="inherit"
        variant={isMobileView ? 'fullWidth' : 'standard'}
        // aria-label="full width tabs example"
      >
        {navItems.map(item => (
          <Tab
            key={item.key}
            value={item.key}
            label={item.title}
            component={Link}
            to={item.key}
          />
        ))}
      </Tabs>
      {currentPrimaryHasChildren && (
        <Tabs
          id="detail-nav"
          value={secondaryNavValue ?? false}
          onChange={(e, newSecondaryValue) => setSecondaryNavValue(newSecondaryValue)}
          // indicatorColor="secondary"
          // textColor="inherit"
          variant={isMobileView ? 'fullWidth' : 'standard'}
          // aria-label="full width tabs example"
        >
          {navItems.find(item => item.key === primaryNavValue).children?.map(item => (
            <Tab
              key={item.key}
              value={item.key}
              label={!isMobileView || (isMobileView && item.icon == null) ? item.title : null}
              icon={<Icon>{item.icon}</Icon>}
              iconPosition="start"
              component={Link}
              to={item.key}
            />
          ))}
        </Tabs>
      )}
    </>
  );
}

export default DetailNav;
