import React from 'react';
import { t } from '../../../common/i18n/translate';
import { formatDate } from '../../../common/modules/helpers';

function pruefungsKategorieFuer(pruefung, veranstaltungsLocale) {
  if (parseInt(pruefung.disziplin_id) === 3) { // fahren
    return (
      <>
        {pruefung.kategorie_text}
        <br />
        {pruefung.fahren_nur_pony ? (
          <>
            {t('fuer_ponies', veranstaltungsLocale)}
            <br />
          </>
        ) : ''}
        {pruefung.fahren_nur_pferde ? (
          <>
            {t('fuer_pferde', veranstaltungsLocale)}
            <br />
          </>
        ) : ''}
      </>
    );
  }
  return pruefung.kategorie_text;
}

function pruefungsDetailsFuer(pruefung, veranstaltungsLocale) {
  let detailFelder = [];
  if (parseInt(pruefung.disziplin_id) === 1) { // endurance
    detailFelder = ['endurance_distanz', 'endurance_teilnehmer', 'endurance_schlaufen'];
  }
  if (parseInt(pruefung.disziplin_id) === 2) { // dressur
    detailFelder = ['lizenzierung_gruppe_text', 'dressur_teilnehmer', 'dressur_rechteck', 'programme'];
  }
  if (parseInt(pruefung.disziplin_id) === 3) { // fahren
    detailFelder = [];
    const potentielleFahrenDetailFelder = [
      'fahren_dressur',
      'fahren_gelaende',
      'fahren_hindernis',
      'fahren_derby',
      'fahren_multitest',
      'fahren_freier_text',
    ];
    potentielleFahrenDetailFelder.forEach((feldName) => {
      if (pruefung[`${feldName }_aktiv`]) {
        detailFelder.push(feldName);
      }
    });
  }
  if (parseInt(pruefung.disziplin_id) === 4) { // concours complet
    const potentielleConcoursDetailFelder = [
      'concours_complet_dressur',
      'concours_complet_springen',
      'concours_complet_cross',
    ];
    potentielleConcoursDetailFelder.forEach((feldName) => {
      if (pruefung[`${feldName }_aktiv`]) {
        detailFelder.push(feldName);
      }
    });
  }
  if (parseInt(pruefung.disziplin_id) === 6) { // springen
    detailFelder = ['wertung_text'];
  }
  if (parseInt(pruefung.disziplin_id) === 9) { // voltige
    // keine Details für Voltige
  }
  if (parseInt(pruefung.disziplin_id) === 12) { // para dressage
    detailFelder = ['lizenzierung_gruppe_text', 'dressur_teilnehmer', 'dressur_rechteck'];
  }

  return (
    <>
      {detailFelder.map((detailFeld) => {
        if (detailFeld === 'gespanne_fuer_fahren') {
          const gespanne = [];
          const potentielleGespanne = [
            'fahren_gespanne_1_aktiv',
            'fahren_gespanne_2_aktiv',
            'fahren_gespanne_4_aktiv',
            'fahren_gespanne_tandem_aktiv',
          ];
          potentielleGespanne.forEach((feldName) => {
            if (pruefung[feldName]) {
              gespanne.push(t(feldName, veranstaltungsLocale));
            }
          });
          return (
            <span key={detailFeld}>
              {t(detailFeld, veranstaltungsLocale)}
              :
              {gespanne.map(g => g).join(', ')}
              {pruefung.fahren_nur_pony && ` / ${t('pony', veranstaltungsLocale)}`}
              {pruefung.fahren_nur_pferde && ` / ${t('pferde', veranstaltungsLocale)}`}
              <br />
            </span>
          );
        }
        if (detailFeld === 'programme') {
          return (
            <span key={detailFeld}>
              {pruefung[detailFeld]}
            </span>
          );
        }
        if (pruefung[detailFeld]) {
          return (
            <span key={detailFeld}>
              {t(detailFeld, veranstaltungsLocale)}
              :
              {pruefung[detailFeld]}
              <br />
            </span>
          );
        }
        return <span key={detailFeld} />;
      })}
    </>
  );
}

export function pruefungenTabelle(pruefungen, veranstaltungsLocale, onRowClick = null) {
  const setHover = onRowClick != null;

  const spaltenBreiten = [
    '2.2%',
    '6.6%',
    '2.2%',
    '8.1%',
    '17%',
    '3.6%',
    '15.75%',
    '44.55%',
  ];

  return (
    <table className="ausschreibung-pruefungen">
      <thead>
        <tr>
          <th style={{ width: spaltenBreiten[0] }}>{t('nummer_nr', veranstaltungsLocale)}</th>
          <th style={{ width: spaltenBreiten[1] }}>{t('datum', veranstaltungsLocale)}</th>
          <th style={{ width: spaltenBreiten[2] + spaltenBreiten[3] }} colSpan={2}>{t('kategorie', veranstaltungsLocale)}</th>
          <th style={{ width: spaltenBreiten[4] }}>{t('details', veranstaltungsLocale)}</th>
          <th style={{ width: spaltenBreiten[5] }}>{t('nenngeld_ng', veranstaltungsLocale)}</th>
          <th style={{ width: spaltenBreiten[6] }}>{t('preise', veranstaltungsLocale)}</th>
          <th style={{ width: spaltenBreiten[7], minWidth: spaltenBreiten[7] }}>{t('bemerkungen', veranstaltungsLocale)}</th>
        </tr>
      </thead>
      <tbody>
        {pruefungen.map((pruefung) => {
          if (pruefung.titel) {
            return (
              <tr
                onClick={onRowClick ? () => onRowClick(pruefung.id) : null}
                key={pruefung.id}
                className={setHover ? 'table-hover-row' : null}
              >
                <td colSpan={8} style={{ textAlign: 'center' }}>
                  <b>{pruefung.titel}</b>
                </td>
              </tr>
            );
          }
          return (
            <tr
              onClick={onRowClick ? () => onRowClick(pruefung.id) : null}
              key={pruefung.id}
              className={(setHover ? 'table-hover-row' : '') + (pruefung.valid ? '' : ' invalid-pruefung')}
            >
              <td style={{ verticalAlign: 'top', width: spaltenBreiten[0] }}>{pruefung.nummer}</td>
              <td style={{ verticalAlign: 'top', width: spaltenBreiten[1] }}>{formatDate(new Date(pruefung.datum), 'dd.mm.YY')}</td>
              <td style={{ verticalAlign: 'top', width: spaltenBreiten[2] }}>{pruefung.modus_code}</td>
              <td style={{ verticalAlign: 'top', width: spaltenBreiten[3] }}>{pruefungsKategorieFuer(pruefung, veranstaltungsLocale)}</td>
              <td style={{ verticalAlign: 'top', width: spaltenBreiten[4] }}>{pruefungsDetailsFuer(pruefung, veranstaltungsLocale)}</td>
              <td style={{ verticalAlign: 'top', width: spaltenBreiten[5] }}>{pruefung.nenngeld}</td>
              <td style={{ verticalAlign: 'top', width: spaltenBreiten[6] }}>{pruefung.preise}</td>
              <td style={{ verticalAlign: 'top', width: spaltenBreiten[7], minWidth: spaltenBreiten[7] }}>
                {pruefung.bemerkungen}
                <br/>
                {pruefung.springen_anzahl_stufen > 1 && Object.keys(pruefung.springen_stufen_hoehen).length > 1 ? t('stufen_anzeige', veranstaltungsLocale).replace('%{stufen_details}', Object.entries(pruefung.springen_stufen_hoehen).map(([key, value]) => `${key} - ${value}cm`).join(' / ')) : ""}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
